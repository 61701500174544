import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`obituary?${params}`)
  },

  async get (id) {
    return await axios.get(`obituary/${id}`)
  },

  async insert (data) {
    return await axios.post('obituary', data)
  },

  async update (id, data) {
    return await axios.put(`obituary/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`obituary/${id}`)
  },

}
