<template>
  <div>
    <core-toolbar>OBITUÁRIO</core-toolbar>

    <v-container class="mt-2">
      <v-row justify="center">
        <v-col cold="12" md="8">
          <v-row class="mb-2">
            <v-col>
              <v-btn
                href="https://wa.me/555199838280?text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20da%20Funer%C3%A1ria%20Huwe%2C%20gostaria%20de%20enviar%20flores."
                target="_blank"
                depressed
                dark
                color="green"
                class="mb-2"
              >
                <v-icon left color="white" v-text="'mdi-whatsapp'" />
                <b>Envie flores</b>
              </v-btn>
            </v-col>
          </v-row>
          <p v-if="obituary.length <= 0" class="body-1">
            Nenhum falecimento registrado nos últimos 7 dias.
          </p>
          <v-expansion-panels>
            <v-expansion-panel v-for="o in obituary" :key="o.id">
              <v-expansion-panel-header>
                <span class="display-1 text-huwe">
                  {{ o.deathDate | date }} - {{ o.name.toUpperCase() }}
                  <template v-if="o.birthDate && o.deathDate">
                     - {{ age(o.birthDate, o.deathDate) }} ANOS
                  </template>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-sheet color="grey lighten-5" class="pa-5">
                  <v-row>
                    <v-col md="10">
                      <v-text-field
                        :value="o.name"
                        label="Nome"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="age(o.birthDate, o.deathDate)"
                        label="Idade"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="o.genre === 'M' ? 'Masculino' : 'Feminino'"
                        label="Sexo"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="o.birthDate | date"
                        label="Data de nascimento"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="o.deathDate | date"
                        label="Data de falecimento"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="o.funeralDate | dateTime"
                        label="Data/hora do velório"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="o.burialDate | dateTime"
                        label="Data/hora do sepultamento"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="o.funeralAddress"
                        label="Local do velório"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="o.burialAddress"
                        label="Local do sepultamento"
                        hide-details
                        readonly
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import obituaryApi from '@/api/obituary'
import dayjs from 'dayjs'

export default {
  components: {
    CoreToolbar: () => import('@/components/site/core/CoreToolbar'),
  },

  data: () => ({
    loading: false,
    obituary: [],
  }),

  mounted() {
    this.loadObituary()
  },

  methods: {
    async loadObituary() {
      try {
        this.loading = true

        const response = await obituaryApi.list({
          startDeathDate: dayjs()
            .subtract(7, 'days')
            .format('YYYY-MM-DD'),
        })

        this.obituary = response.data.obituary
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    age(birthDate, deathDate) {
      const date1 = dayjs(deathDate)
      const date2 = dayjs(birthDate)

      return date1.diff(date2, 'year')
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
